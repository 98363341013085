
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  font-family: "Urbanist", sans-serif !important;
}

.font-poppins-o{
  font-family: "Poppins", sans-serif;}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000; 
}


.responsive-63-35 {
  @apply text-35 xl:text-63 leading-[2.4rem] xl:leading-[4rem]
}

.responsive-63-45 {
  @apply text-45 xl:text-63 leading-[2.4rem] xl:leading-[4rem]
}


.custom-linear-gradient {
  background: rgb(0,0,0);
background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(2,31,93,0) 100%);
background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(2,31,93,0) 100%);
background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(2,31,93,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#021f5d",GradientType=1);
}